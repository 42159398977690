<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <mdb-row class="mb-3">
              <mdb-col>
                <h1>Claims</h1>
              </mdb-col>
            </mdb-row>
            Under construction...
          </mdb-container>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'documentationCenter',
  data() {
    return {
      alerts: [],
      isloading: true,
      confirmdelete: false,
      alerttodelete: null,
      error: null,
    };
  },
  computed: {
    activealerts() {
      const alerttoreturn = [];
      this.alerts.forEach((alert) => {
        if (new Date(alert.startat).getTime() <= new Date().getTime()) {
          if (!alert.endat) {
            alerttoreturn.push(alert);
          } else if (new Date(alert.endat).getTime() >= new Date().getTime()) {
            alerttoreturn.push(alert);
          }
        }
      });
      return alerttoreturn;
    },
    pastalerts() {
      const alerttoreturn = [];
      this.alerts.forEach((alert) => {
        if (new Date(alert.endat).getTime() <= new Date().getTime() && alert.endat) {
          alerttoreturn.push(alert);
        }
      });
      return alerttoreturn;
    },
    futuralerts() {
      const alerttoreturn = [];
      this.alerts.forEach((alert) => {
        if (new Date(alert.startat).getTime() > new Date().getTime()) {
          alerttoreturn.push(alert);
        }
      });
      return alerttoreturn;
    },
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbRow,
    mdbCol,
  },
  mounted() {
    console.log('blabla');
  },
  methods: {
    loadAllThraeads() {
      console.log('asd');
    },
  },
};
</script>
